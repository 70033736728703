import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{staticStyle:{"width":"auto"},attrs:{"absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[_c(VBtn,{staticStyle:{"border-radius":"25px"},attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[_c('span',{staticStyle:{"letter-spacing":"-8px","opacity":"0"}},[_vm._v("setDrawer")]),(_vm.value)?_c(VIcon,[_vm._v(" mdi-view-quilt ")]):_c(VIcon,[_vm._v(" mdi-dots-vertical ")])],1),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.client === _vm.$store.state.version.EDWARDS),expression:"$store.state.client === $store.state.version.EDWARDS"}],staticClass:"cx-logo",attrs:{"alt":"logo","src":require("@/assets/images/EdwardsLogo.png"),"height":"auto","width":"50"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.client === _vm.$store.state.version.ORSTED),expression:"$store.state.client === $store.state.version.ORSTED"}],staticClass:"cx-logo",attrs:{"alt":"logo","src":require("@/assets/images/orsted.png"),"height":"auto","width":"50"}}),_c(VToolbarTitle,{staticClass:"hidden-sm-and-down cx-title"},[_vm._v(_vm._s(_vm.getCampusName)+" ")]),_c(VSpacer),_c('div',{staticClass:"mx-3"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }