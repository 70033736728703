<template>
  <v-app-bar
    absolute
    app
    color="transparent"
    flat
    height="75"
    style="width: auto;"
  >
    <!--@click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"-->
    <v-btn
      fab
      small
      @click="setDrawer(!drawer)"
      style="border-radius: 25px"
    >
      <span style="letter-spacing: -8px;opacity: 0">setDrawer</span>
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <img
      alt="logo"
      v-show="$store.state.client === $store.state.version.EDWARDS"
      src="@/assets/images/EdwardsLogo.png"
      height="auto"
      width="50"
      class="cx-logo"
    />
    <img
      alt="logo"
      v-show="$store.state.client === $store.state.version.ORSTED"
      src="@/assets/images/orsted.png"
      height="auto"
      width="50"
      class="cx-logo"
    />

    <!--<v-toolbar-title-->
    <!--class="hidden-sm-and-down"-->
    <!--v-text="$route.name"-->
    <!--/>-->

    <!--<v-toolbar-title-->
    <!--  class="hidden-sm-and-down cx-title"-->
    <!--&gt;Content Management System</v-toolbar-title>-->
    <!--<v-toolbar-title-->
    <!--  class="hidden-sm-and-down cx-title"-->
    <!--&gt;2nd Century</v-toolbar-title>-->
    <v-toolbar-title
      class="hidden-sm-and-down cx-title"
    >{{ getCampusName }}
    </v-toolbar-title>

    <v-spacer/>

    <!--<v-text-field-->
    <!--:label="$t('search')"-->
    <!--color="secondary"-->
    <!--hide-details-->
    <!--class="mb-2"-->
    <!--style="max-width: 165px;"-->
    <!--&gt;-->
    <!--<template-->
    <!--v-if="$vuetify.breakpoint.mdAndUP"-->
    <!--v-slot:append-outer-->
    <!--&gt;-->
    <!--<v-btn-->
    <!--class="mt-n2"-->
    <!--fab-->
    <!--small-->
    <!--&gt;-->
    <!--<v-icon>mdi-magnify</v-icon>-->
    <!--</v-btn>-->
    <!--</template>-->
    <!--</v-text-field>-->

    <div class="mx-3"/>

    <!--<v-btn-->
    <!--min-width="0"-->
    <!--text-->
    <!--&gt;-->
    <!--<v-icon>mdi-view-dashboard</v-icon>-->
    <!--</v-btn>-->

    <!--<v-menu-->
    <!--bottom-->
    <!--left-->
    <!--offset-y-->
    <!--origin="top right"-->
    <!--transition="scale-transition"-->
    <!--&gt;-->
    <!--<template v-slot:activator="{ attrs, on }">-->
    <!--<v-btn-->
    <!--min-width="0"-->
    <!--text-->
    <!--v-bind="attrs"-->
    <!--v-on="on"-->
    <!--&gt;-->
    <!--<v-badge-->
    <!--color="red"-->
    <!--overlap-->
    <!--&gt;-->
    <!--<template v-slot:badge>-->
    <!--<span class="caption">5</span>-->
    <!--</template>-->

    <!--<v-icon>mdi-bell</v-icon>-->
    <!--</v-badge>-->
    <!--</v-btn>-->
    <!--</template>-->

    <!--<v-list-->
    <!--:tile="false"-->
    <!--nav-->
    <!--&gt;-->
    <!--<div>-->
    <!--<app-bar-item-->
    <!--v-for="(n, i) in notifications"-->
    <!--:key="`item-${i}`"-->
    <!--&gt;-->
    <!--<v-list-item-title v-text="n" />-->
    <!--</app-bar-item>-->
    <!--</div>-->
    <!--</v-list>-->
    <!--</v-menu>-->

    <!--<v-menu-->
    <!--bottom-->
    <!--left-->
    <!--min-width="200"-->
    <!--offset-y-->
    <!--origin="top right"-->
    <!--transition="scale-transition"-->
    <!--&gt;-->
    <!--<template v-slot:activator="{ attrs, on }">-->
    <!--<v-btn-->
    <!--min-width="0"-->
    <!--text-->
    <!--v-bind="attrs"-->
    <!--v-on="on"-->
    <!--&gt;-->
    <!--<v-icon>mdi-account</v-icon>-->
    <!--</v-btn>-->
    <!--</template>-->

    <!--<v-list-->
    <!--:tile="false"-->
    <!--flat-->
    <!--nav-->
    <!--&gt;-->
    <!--<template v-for="(p, i) in profile">-->
    <!--<v-divider-->
    <!--v-if="p.divider"-->
    <!--:key="`divider-${i}`"-->
    <!--class="mb-2 mt-2"-->
    <!--/>-->

    <!--<app-bar-item-->
    <!--v-else-->
    <!--:key="`item-${i}`"-->
    <!--to="/"-->
    <!--&gt;-->
    <!--<v-list-item-title v-text="p.title" />-->
    <!--</app-bar-item>-->
    <!--</template>-->
    <!--</v-list>-->
    <!--</v-menu>-->
  </v-app-bar>
</template>

<script>
import axios from "axios";
// Components
import {VHover, VListItem} from 'vuetify/lib'

// Utilities
import {mapState, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'DashboardCoreAppBar',

  // data() {
  //   return {
  //     version: '',
  //   }
  // },

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => h(VListItem, {
              attrs: this.$attrs,
              class: {
                'black--text': !hover,
                'white--text secondary elevation-12': hover
              },
              props: {
                activeClass: '',
                dark: hover,
                link: true,
                ...this.$attrs
              }
            }, this.$slots.default)
          }
        })
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    notifications: [
      'Mike John Responded to your email',
      'You have 5 new tasks',
      `You're now friends with Andrew`,
      'Another Notification',
      'Another one'
    ],
    profile: [
      {title: 'Profile'},
      {title: 'Settings'},
      {divider: true},
      {title: 'Log out'}
    ]
  }),

  computed: {
    ...mapState(['drawer']),
    ...mapGetters('campusName',['getCampusName']),
  },

  methods: {
    ...mapMutations({setDrawer: 'SET_DRAWER'}),
    ...mapMutations('campusName',["setCampusName"]),
  },
  created() {
    this.setCampusName(localStorage.getItem("campusName"));
    const _this = this;
    axios
      .get("cms/config")
      .then((response) => {
        console.log(response);
        _this.$store.commit("setClient", response.data.Client);
        _this.version = response.data.Client;
        console.log(this.$store.state.client);
        // console.log(_this.$store.state.client === _this.$store.state.version.ORSTED);
        // console.log(_this.$store);
        _this.$forceUpdate();
      })
      .catch((err) => {
        console.log(err);
      });

  },
}
</script>
<style lang="sass">
.v-badge__badge
  padding: 1px 0

.cx-logo
  margin: 0 12px

.cx-title
  font-size: 1.6rem
  margin-left: 12px
  margin-bottom: 0px
</style>
